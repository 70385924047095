import React from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import UtilButtonV2 from '../utils/UtilButtonV2'
import BaseImageV2 from '@/v2/base/BaseImageV2'

type Props = {
  blok: SbBlokData & ISbSectionStageV2
}

const SectionStageV2 = ({ blok }: Props) => {
  const hasAnyKpiItems = !!blok.kpi_items?.length

  return (
    <div
      className={`grid gap-8 bg-v2-secondary-static-2 pt-8 lg:gap-16 lg:pt-32.5 ${hasAnyKpiItems ? 'shadow-[inset_0_-48px_0px_0px_white]' : 'pb-10 lg:pb-20'}`}
      {...storyblokEditable(blok)}
    >
      {/* First column */}
      <div className="container-v2 z-10 mx-auto gap-20">
        <div className="grid items-center gap-12 pt-2 lg:grid-cols-2">
          <div className="flex flex-col items-start gap-7 lg:gap-8">
            <div className="flex flex-col justify-start gap-6">
              <h1 className="heading-2 whitespace-pre-line text-v2-text-primary">{blok.title}</h1>
              {blok.text && <p className="text-default whitespace-pre-line text-v2-text-secondary ">{blok.text}</p>}
            </div>
            {!!blok.button?.length && (
              <div className="w-full md:w-auto">
                <UtilButtonV2 blok={blok.button[0]} />
              </div>
            )}
          </div>
          {/* Second column */}
          <div className="relative">
            <div className="relative overflow-hidden rounded-5xl rounded-br-none">
              <BaseImageV2
                src={blok.image.filename}
                alt={blok.image.alt}
                focus={blok.image.focus}
                breakPointsWidthMap={{ 0: 608 }}
                lazy={false}
                className="h-full w-full [&_img]:h-full [&_img]:w-full"
              />
              <div className="absolute left-0 top-0 h-full w-full rounded-5xl rounded-br-none border border-v2-foreground-static-tertiary"></div>
            </div>

            <div className="absolute bottom-0 right-0 max-w-37.5">
              <BaseImageV2 src={blok.logo.filename} alt={blok.logo.alt} breakPointsWidthMap={{ 0: 150 }} lazy={false} />
            </div>
          </div>
        </div>

        {/* KPI items */}
        {!!hasAnyKpiItems && (
          <div
            className={`z-10 mx-auto mt-8 grid max-w-5xl grid-cols-2 gap-8 justify-self-center rounded-5xl border border-v2-foreground-static-tertiary bg-white p-8 md:gap-0 md:divide-x md:p-10 lg:mt-20 md:grid-cols-${blok.kpi_items.length}`}
          >
            {blok.kpi_items.map((item, index) => (
              <div
                key={index}
                className={`${index < blok.kpi_items.length - 1 ? 'w-full ' : ''}flex items-end md:justify-center`}
                {...storyblokEditable(item)}
              >
                <div className="flex h-full flex-col items-start justify-end gap-2 px-3">
                  <BaseImageV2
                    className={`w-fit max-w-7.5 ${item.logo.filename ? 'objec h-12 w-full max-w-[11rem]' : 'mb-1'}`}
                    imageClassName="max-h-full object-contain object-left "
                    src={item.logo.filename || item.icon.filename}
                    alt={item.logo.alt || item.icon.alt}
                    breakPointsWidthMap={{ 0: item.logo.filename ? 175 : 100 }}
                  />

                  <div className="flex flex-col gap-1.5 ">
                    {item.title && <span className="heading-5 text-v2-text-primary">{item.title}</span>}
                    {item.subtitle && <p className="body-3 text-v2-text-primary">{item.subtitle}</p>}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SectionStageV2
